<template>
  <v-container
    fluid
    grid-list-{xs
    through
    xl}
  >
    <v-layout justify-start
              raw
              wrap
              style="display:block"
    >
      <div class="form-cls">
        <form id="role-form"
              class="form"
              @submit.prevent="onSubmit"
        >
          <h4 class="form-hd">
            Select Your Role
          </h4>
          <input
            v-model="register.role"
            type="radio"
            name="role"
            value="teacher"
            checked
            required
          >
          <label for="male" class="lbl-mgn">Teacher</label><br>
          <input
            v-model="register.role"
            type="radio"
            name="role"
            value="student"
          >
          <label for="male" class="lbl-mgn">Student</label>
          <br>
          <div style="margin-top:10px">
            <input v-model="register.referralCode"
                   name="subject"
                   placeholder="Referral Code"
                   type="text"
                   autocomplete="off"
            >
          </div>
          <v-btn
            color=" red lighten-2"
            dark
            v-bind="attrs"
            class="form-ft"
            v-on="on"
            @click="onSubmit()"
          >
            Submit
          </v-btn>
          <!--<button class="button" @click="onSubmit()"> <b> Send </b></button>-->
        </form>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
    export default {
      name: 'Register',
      components: {
      },
      data () {
        return {
            register: {
			    role: "",
                referralCode: ""
            },
            provider: null,
            token: null
        }
      },
      computed: {
        ...mapGetters({
          topics: 'navigation/Register'
        })
      },
      mounted () {
        //   console.log("mount::", this.$route.query.refresh_token)
          this.provider = this.$route.query.provider
          this.token = this.$route.query.refresh_token
      },
      created: function () {
        this.$store.commit('navigation/resetState');
        //if (localStorage.getItem('reloaded')) {
        //        localStorage.removeItem('reloaded');
        //    } else {
        //        localStorage.setItem('reloaded', '1');
        //        location.reload();
        //    };
        let registerRole =true;
        this.$store.commit('navigation/registerRole', registerRole);
        let newPhys =false;
        this.$store.commit('navigation/replacePhys', newPhys);
        let newPNP =false;
        this.$store.commit('navigation/replacePNP', newPNP);
      },
      methods: {
        async onSubmit(){
            // console.log("submit",this.register)
            if(this.register.role != ""){
                let postData = {
                        "provider": this.provider,
                        "user_role": this.register.role,
                        "refresh_token": this.token
                    }
                if(this.register.referralCode) postData.ref_id = this.register.referralCode
                const response = await axios.post(process.env.VUE_APP_API+'auth/social/register', postData)
                console.log(response, response.data.message)
                if(response.data.success) {
                    localStorage.setItem('token', response.data.data.access_token)
                    localStorage.setItem('userId', response.data.data.user_id)
                    this.$notify({
                        group: 'notify',
                        type: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    // this.$router.push('/Dashboard')
                    window.location.href = '/Dashboard'
                } else {
                    this.$notify({
                        group: 'notify',
                        type: 'error',
                        title: 'Error!!',
                        text: response.data.message
                    });
                }
            } else {
                // console.log("error:", this.register)
                this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Error!!',
                    text: 'Please select the role'
                });
            }

        },
      },
    }
</script>
<style lang="scss">
// @import '@/styles/screen-sizes.scss';

    .form-cls {
    margin: auto;
    width: 25%;
    padding: 20px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px 0px 50px 0px;
    border: 2px solid;
}

    .form {
        border: none;
    }
    .lbl-mgn {
        margin-left: 3%;
    }
    .form-hd {
        margin-bottom: 5%;
    }
    .form-ft {
        margin-top: 5%;
    }

    input[type="text"] {
    border-bottom: 1px solid;
    text-align: center;
    }


</style>
